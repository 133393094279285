import { fetchCurrentUser } from './authentication.ts';
import { fetchCategories } from './products.ts';

export const currentUserQueryOptions = {
    queryKey: ['currentUser'],
    queryFn: fetchCurrentUser,
};

export const categoryQueryOptions = {
    queryKey: ['categories'],
    queryFn: fetchCategories,
};
