import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App.tsx';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import { CssBaseline } from '@mui/material';
import { z } from 'zod';
import * as Sentry from '@sentry/react';
import { zodI18nMap } from 'zod-i18n-map';
import './i18n';

z.setErrorMap(zodI18nMap);

Sentry.init({
    dsn: import.meta.env.VITE_SENTRY_DSN,
    environment: import.meta.env.PROD ? 'production' : 'development',
    integrations: [
        Sentry.breadcrumbsIntegration(),
        Sentry.replayIntegration(),
        Sentry.httpClientIntegration(),
    ],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    tracesSampleRate: 1.0,

    // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: [
        'localhost:5173',
        /^https:\/\/kassa\.ilgrigio\.nl/,
    ],

    // Capture Replay for 10% of all sessions,
    // plus for 100% of sessions with an error
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,

    // This option is required for capturing headers and cookies.
    sendDefaultPii: true,
});

ReactDOM.createRoot(document.getElementById('root')!).render(
    <React.StrictMode>
        <CssBaseline />
        <App />
    </React.StrictMode>,
);
