import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import translationNLApi from './translations/nl/api.json';
import zodTranslation from 'zod-i18n-map/locales/nl/zod.json';
import translationNLForm from './translations/nl/form.json';
import translationNLSales from './translations/nl/sales.json';
import translationNLCheckout from './translations/nl/checkout.json';
import translationNLLayout from './translations/nl/layout.json';
import translationNLOrders from './translations/nl/orders.json';
import translationNLCoupons from './translations/nl/coupons.json';
import translationNLErrors from './translations/nl/errors.json';

const resources = {
    nl: {
        zod: zodTranslation,
        api: translationNLApi,
        form: translationNLForm,
        checkout: translationNLCheckout,
        layout: translationNLLayout,
        orders: translationNLOrders,
        coupons: translationNLCoupons,
        sales: translationNLSales,
        errors: translationNLErrors,
    },
};

i18next.use(initReactI18next).init({
    lng: 'nl',
    resources: resources,
    ns: [
        'api',
        'form',
        'sales',
        'checkout',
        'orders',
        'coupons',
        'layout',
        'zod',
        'errors',
    ],
    defaultNS: [
        'api',
        'form',
        'checkout',
        'orders',
        'coupons',
        'layout',
        'sales',
        'zod',
        'errors',
    ],
    interpolation: {
        escapeValue: false,
    },
});

export default i18next;
