import { apiClient } from './wordpress-client.ts';
import { SalesReport } from '../types/stats.ts';
import { endOfYear, format, startOfYear } from 'date-fns';

export const fetchSalesReport = async (year: number) => {
    const minDate = startOfYear(new Date(year, 1));
    const maxDate = endOfYear(new Date(year, 1));

    const response = await apiClient.get<SalesReport[]>('wc/v3/reports/sales', {
        params: {
            date_min: format(minDate, 'yyyy-MM-dd'),
            date_max: format(maxDate, 'yyyy-MM-dd'),
        },
    });

    return response.data[0];
};
