import { JwtResponse, User } from '../types/authentication';
import { apiClient } from './wordpress-client';
import { jwtDecode } from 'jwt-decode';

export const createJwtToken = async (username: string, password: string) => {
    const response = await apiClient.post<JwtResponse>(
        'simple-jwt-login/v1/auth',
        {
            username,
            password,
        },
    );

    return response.data;
};

export const fetchCurrentUser = async () => {
    const response = await apiClient.get<User>('wp/v2/users/me');

    return response.data;
};

export const isAuthenticated = async () => {
    const jwtToken = localStorage.getItem('jwtToken');

    if (!jwtToken) {
        return false;
    }

    const decodedToken = jwtDecode(jwtToken);

    return !(
        decodedToken.exp && decodedToken.exp * 1000 < new Date().getTime()
    );
};
