import { Box, Button, Container, Typography } from '@mui/material';
import React from 'react';
import Image from 'mui-image';
import logoUrl from '../../images/Logo-IlGrigioClown.png';
import { useTranslation } from 'react-i18next';

type Props = {
    onReset: () => void;
};

export const ErrorPage: React.FC<Props> = ({ onReset }) => {
    const { t } = useTranslation('errors');

    return (
        <Container component="main" maxWidth="xs">
            <Box
                sx={{
                    marginTop: 8,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <Image src={logoUrl} duration={0} />
                <Typography
                    component="h1"
                    variant="h5"
                    sx={{
                        marginTop: 4,
                    }}
                >
                    {t`A error has occurred`}
                </Typography>
                <Button
                    sx={{
                        marginTop: 4,
                    }}
                    onClick={onReset}
                    variant="contained"
                >
                    {t`Refresh`}
                </Button>
            </Box>
        </Container>
    );
};
