import { Box, Container, Typography } from '@mui/material';
import React from 'react';
import Image from 'mui-image';
import logoUrl from '../../images/Logo-IlGrigioClown.png';

export const NotFound: React.FC = () => {
    return (
        <Container component="main" maxWidth="xs">
            <Box
                sx={{
                    marginTop: 8,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <Image src={logoUrl} duration={0} />
                <Typography
                    component="h1"
                    variant="h5"
                    sx={{
                        marginTop: 4,
                    }}
                >
                    Pagina niet gevonden
                </Typography>
            </Box>
        </Container>
    );
};
