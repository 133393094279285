import {
    Discount as DiscountIcon,
    PointOfSale as PointOfSaleIcon,
    QueryStats as QueryStatsIcon,
    Receipt as ReceiptIcon,
} from '@mui/icons-material';
import React from 'react';
import { MenuItem } from './menu-item';
import { List } from '@mui/material';
import { useTranslation } from 'react-i18next';

type Props = {
    drawerOpen?: boolean;
};

export const MenuItems: React.FC<Props> = ({ drawerOpen }) => {
    const { t } = useTranslation(['layout']);

    return (
        <List>
            <MenuItem
                to="/statistieken"
                text={t('Stats')}
                icon={<QueryStatsIcon />}
                drawerOpen={drawerOpen}
            />
            <MenuItem
                to="/kassa"
                text={t('Checkout')}
                icon={<PointOfSaleIcon />}
                drawerOpen={drawerOpen}
            />
            <MenuItem
                to="/bestellingen"
                text={t('Orders')}
                icon={<ReceiptIcon />}
                drawerOpen={drawerOpen}
            />
            <MenuItem
                to="/kortingscodes"
                text={t('Discount codes')}
                icon={<DiscountIcon />}
                drawerOpen={drawerOpen}
            />
        </List>
    );
};
