import { Box, CircularProgress } from '@mui/material';

type Props = {
    margin?: boolean;
};

const Loader: React.FC<Props> = ({ margin }) => {
    return (
        <Box
            display="flex"
            justifyContent={'center'}
            sx={{
                marginTop: margin ? '8rem' : 0,
            }}
        >
            <CircularProgress />
        </Box>
    );
};

export default Loader;
