import {
    createRootRouteWithContext,
    createRoute,
    createRouter,
    lazyRouteComponent,
    Navigate,
    redirect,
} from '@tanstack/react-router';
import { Index } from '../pages';
import { Root as RootComponent } from '../pages/root';
import { queryClient } from '../api/query-client';
import { QueryClient } from '@tanstack/react-query';
import {
    categoryQueryOptions,
    currentUserQueryOptions,
} from '../api/query-options.ts';
import { NotFound } from '../pages/errors/not-found.tsx';
import { isAuthenticated } from '../api/authentication.ts';
import Loader from '../pages/loader.tsx';
import { getCurrentYear, getLastYear } from '../utility/date.ts';
import { fetchSalesReport } from '../api/stats.ts';

const rootRoute = createRootRouteWithContext<{
    queryClient: QueryClient;
}>()({
    component: RootComponent,
});

const indexRoute = createRoute({
    path: '/',
    getParentRoute: () => rootRoute,
    component: () => <Navigate to="/inloggen" replace />,
});

const loginRoute = createRoute({
    getParentRoute: () => rootRoute,
    path: '/inloggen',
    component: lazyRouteComponent(() => import('../pages/login.tsx'), 'Login'),
});

const layoutRoute = createRoute({
    getParentRoute: () => rootRoute,
    id: 'layout',
    loader: async ({ context: { queryClient } }) =>
        queryClient.ensureQueryData(currentUserQueryOptions),
    pendingComponent: () => <Loader margin={true} />,
    beforeLoad: async () => {
        if (!isAuthenticated) {
            throw redirect({
                to: '/inloggen',
                replace: true,
            });
        }
    },
    component: Index,
});

const statsRoute = createRoute({
    getParentRoute: () => layoutRoute,
    path: '/statistieken',
    component: lazyRouteComponent(() => import('../pages/stats.tsx'), 'Stats'),
    loader: async ({ context: { queryClient } }) => {
        const p1 = queryClient.ensureQueryData({
            queryKey: ['salesReport', getLastYear()],
            queryFn: () => fetchSalesReport(getLastYear()),
        });

        const p2 = queryClient.ensureQueryData({
            queryKey: ['salesReport', getCurrentYear()],
            queryFn: () => fetchSalesReport(getCurrentYear()),
        });

        await Promise.all([p1, p2]);
    },
});

const checkoutRoute = createRoute({
    getParentRoute: () => layoutRoute,
    path: '/kassa',
    component: lazyRouteComponent(
        () => import('../pages/checkout.tsx'),
        'Checkout',
    ),
    loader: async ({ context: { queryClient } }) =>
        queryClient.ensureQueryData(categoryQueryOptions),
});

const ordersRoute = createRoute({
    getParentRoute: () => layoutRoute,
    path: '/bestellingen',
    component: lazyRouteComponent(
        () => import('../pages/orders.tsx'),
        'Orders',
    ),
});

export const couponsRoute = createRoute({
    path: '/kortingscodes',
    getParentRoute: () => layoutRoute,
    component: lazyRouteComponent(
        () => import('../pages/coupons.tsx'),
        'Coupons',
    ),
});

export const ticketsRoute = createRoute({
    path: '/tickets/$orderId',
    getParentRoute: () => rootRoute,
    component: lazyRouteComponent(
        () => import('../pages/tickets.tsx'),
        'Tickets',
    ),
});

const routeTree = rootRoute.addChildren([
    indexRoute,
    layoutRoute,
    checkoutRoute,
    loginRoute,
    ordersRoute,
    ticketsRoute,
    couponsRoute,
    statsRoute,
]);

export const router = createRouter({
    routeTree: routeTree,
    defaultPreload: 'intent',
    defaultStaleTime: Infinity,
    // TODO Fix
    defaultNotFoundComponent: () => <NotFound />,
    context: {
        queryClient,
    },
});
