import axios, { AxiosError } from 'axios';
import { router } from '../router/router.tsx';

export const apiClient = axios.create({
    baseURL: import.meta.env.VITE_BACKEND_URL,
    timeout: 10000,
    headers: {
        Authorization: `Bearer ${localStorage.getItem('jwtToken')}`,
    },
});

apiClient.interceptors.response.use(
    (response) => {
        return response;
    },
    (error: AxiosError): Promise<AxiosError> => {
        console.log('error', error);
        if (error.response && error.response.status === 400) {
            router.history.push('/inloggen');
        }

        return Promise.reject(error);
    },
);
