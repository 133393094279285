import {
    Link,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
} from '@mui/material';
import { ToPathOption, useNavigate } from '@tanstack/react-router';
import { ReactElement } from 'react';
import { useActiveRoute } from '../../hooks/useActiveRoute';

type Props = {
    icon: ReactElement;
    text: string;
    to: ToPathOption;
    drawerOpen?: boolean;
};

export const MenuItem: React.FC<Props> = ({ icon, text, to, drawerOpen }) => {
    const navigate = useNavigate();
    const isActiveRoute = useActiveRoute();

    return (
        <Link underline="none" onClick={() => navigate({ to })}>
            <ListItem disablePadding sx={{ display: 'block' }}>
                <ListItemButton
                    sx={{
                        minHeight: 48,
                        justifyContent: drawerOpen ? 'initial' : 'center',
                        px: 2.5,
                    }}
                    selected={isActiveRoute(to)}
                >
                    <ListItemIcon
                        sx={{
                            minWidth: 0,
                            mr: drawerOpen ? 3 : 'auto',
                            justifyContent: 'center',
                        }}
                    >
                        {icon}
                    </ListItemIcon>
                    <ListItemText
                        sx={{ opacity: drawerOpen ? 1 : 0 }}
                        primary={text}
                    />
                </ListItemButton>
            </ListItem>
        </Link>
    );
};
