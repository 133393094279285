import {
    AccountCircle as AccountCircleIcon,
    ChevronLeft as ChevronLeftIcon,
    Logout as LogoutIcon,
    Menu as MenuIcon,
} from '@mui/icons-material';
import {
    AppBar as MuiAppBar,
    AppBarProps as MuiAppBarProps,
    Box,
    Button,
    Container,
    CSSObject,
    Divider,
    Drawer as MuiDrawer,
    IconButton,
    ListItemIcon,
    Menu,
    MenuItem,
    styled,
    Theme,
    Toolbar,
    Typography,
} from '@mui/material';
import { useSuspenseQuery } from '@tanstack/react-query';
import { Outlet, useNavigate } from '@tanstack/react-router';
import React from 'react';
import { MenuItems } from '../components/index/menu-items';
import { useAuth } from '../hooks/useAuth';
import { useTranslation } from 'react-i18next';
import { currentUserQueryOptions } from '../api/query-options.ts';

const drawerWidth: number = 240;

interface AppBarProps extends MuiAppBarProps {
    open?: boolean;
}

const openedMixin = (theme: Theme): CSSObject => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up('sm')]: {
        width: `calc(${theme.spacing(8)} + 1px)`,
    },
});

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

const Drawer = styled(MuiDrawer, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
        ...openedMixin(theme),
        '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
        ...closedMixin(theme),
        '& .MuiDrawer-paper': closedMixin(theme),
    }),
}));

export const Index: React.FC = () => {
    const { t } = useTranslation('form');
    const { data: currentUser } = useSuspenseQuery(currentUserQueryOptions);
    const { setJwtToken } = useAuth();
    const navigate = useNavigate();
    const [drawerOpen, setDrawerOpen] = React.useState(false);

    const [userMenuAnchorEl, setUserMenuAnchorEl] =
        React.useState<null | HTMLElement>(null);

    const handleUserMenuClick = (event: React.MouseEvent<HTMLElement>) => {
        setUserMenuAnchorEl(event.currentTarget);
    };

    const handleUserMenuClose = () => {
        setUserMenuAnchorEl(null);
    };

    const handleDrawerOpen = () => {
        setDrawerOpen(true);
    };

    const handleDrawerClose = () => {
        setDrawerOpen(false);
    };

    const handleLogout = () => {
        setJwtToken(null);
        navigate({ to: '/inloggen' });
    };

    if (!currentUser) {
        return null;
    }

    return (
        <Box sx={{ display: 'flex' }}>
            <AppBar position="fixed" open={drawerOpen}>
                <Toolbar variant="dense">
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        onClick={handleDrawerOpen}
                        edge="start"
                        sx={{
                            marginRight: 5,
                            ...(drawerOpen && { display: 'none' }),
                        }}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Typography
                        component="h1"
                        variant="h6"
                        color="inherit"
                        noWrap
                        sx={{ flexGrow: 1 }}
                    >
                        POS - Jeugdcircus Il Grigio
                    </Typography>
                    <Button
                        size="large"
                        onClick={handleUserMenuClick}
                        color="inherit"
                        startIcon={<AccountCircleIcon />}
                    >
                        {currentUser.name}
                    </Button>
                    <Menu
                        id="menu-appbar"
                        anchorEl={userMenuAnchorEl}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'center',
                        }}
                        keepMounted
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'center',
                        }}
                        open={Boolean(userMenuAnchorEl)}
                        onClose={handleUserMenuClose}
                    >
                        <MenuItem onClick={handleLogout}>
                            <ListItemIcon>
                                <LogoutIcon fontSize="small" />
                            </ListItemIcon>
                            {t('Logout')}
                        </MenuItem>
                    </Menu>
                </Toolbar>
            </AppBar>
            <Drawer variant="permanent" open={drawerOpen}>
                <DrawerHeader>
                    <IconButton onClick={handleDrawerClose}>
                        <ChevronLeftIcon />
                    </IconButton>
                </DrawerHeader>
                <Divider />
                <MenuItems drawerOpen={drawerOpen} />
            </Drawer>
            <Box
                component="main"
                sx={{
                    display: 'flex',
                    flexGrow: 1,
                    height: '100vh',
                    overflow: 'hidden',
                    background: '#d3d3d336',
                }}
            >
                <Container
                    sx={{
                        paddingTop: '3rem',
                        paddingBottom: '3rem',
                        height: '100%',
                    }}
                    maxWidth={false}
                >
                    <Box
                        sx={{
                            marginTop: '1rem',
                            height: '100%',
                        }}
                    >
                        <Outlet />
                    </Box>
                </Container>
            </Box>
        </Box>
    );
};
