import { Outlet } from '@tanstack/react-router';
import React from 'react';
import {
    ReactQueryDevtoolsProduction,
    TanStackRouterDevtools,
} from '../devtools';
import { AuthProvider } from '../context/auth-provider';
import { SnackbarProvider } from 'notistack';
import * as Sentry from '@sentry/react';
import { ErrorPage } from './errors/errors.tsx';

export const Root: React.FC = () => {
    return (
        <Sentry.ErrorBoundary
            fallback={({ resetError }) => <ErrorPage onReset={resetError} />}
        >
            <AuthProvider>
                <SnackbarProvider autoHideDuration={5000}>
                    <Outlet />
                    <TanStackRouterDevtools position="bottom-left" />
                    <ReactQueryDevtoolsProduction position={'bottom'} />
                </SnackbarProvider>
            </AuthProvider>
        </Sentry.ErrorBoundary>
    );
};
