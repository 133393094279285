import { Categories, ProductVariations, Products } from '../types/product';
import { apiClient } from './wordpress-client';

export const fetchCategories = async () => {
    return apiClient
        .get<Categories>('wc/v3/products/categories')
        .then((response) => response.data);
};

export type ProductsFilter = {
    categoryId: number;
    searchTerm: string | null;
};

export const fetchProducts = async (filter: ProductsFilter) => {
    return apiClient
        .get<Products>('wc/v3/products', {
            params: {
                category: filter.categoryId,
                search: filter.searchTerm ?? null,
                per_page: 100,
                order: 'asc',
            },
        })
        .then((response) => response.data);
};

export const fetchProductsVariations = async (productId: number) => {
    return apiClient
        .get<ProductVariations>(`wc/v3/products/${productId}/variations`)
        .then((response) => response.data);
};
