import { createTheme } from '@mui/material';
import { nlNL } from '@mui/material/locale';

export const theme = createTheme(
    {
        palette: {
            primary: {
                main: '#243B53',
                light: '#E2E8F0',
            },
            secondary: {
                main: '#127FBF',
            },
        },
        mixins: {
            toolbar: {
                minHeight: 48,
            },
        },
    },
    nlNL,
);
