import React, { createContext, useEffect, useMemo } from 'react';
import { apiClient } from '../api/wordpress-client';

export type AuthContextType = {
    setJwtToken: (jwtToken: string | null) => void;
    jwtToken: string | null;
};

export const AuthContext = createContext<AuthContextType>({
    setJwtToken: () => {},
    jwtToken: null,
});

export const AuthProvider: React.FC<{
    children: React.ReactNode;
}> = ({ children }) => {
    const setJwtToken = (newToken: string | null) => {
        setJwtToken_(newToken);
    };

    const [jwtToken, setJwtToken_] = React.useState<string | null>(
        localStorage.getItem('jwtToken'),
    );

    useEffect(() => {
        if (jwtToken) {
            apiClient.defaults.headers.common['Authorization'] =
                'Bearer ' + jwtToken;
            localStorage.setItem('jwtToken', jwtToken);
        } else {
            delete apiClient.defaults.headers.common['Authorization'];
            localStorage.removeItem('jwtToken');
        }
    }, [jwtToken]);

    const contextValue = useMemo(
        () => ({
            jwtToken,
            setJwtToken,
        }),
        [jwtToken],
    );

    return (
        <AuthContext.Provider value={contextValue}>
            {children}
        </AuthContext.Provider>
    );
};
