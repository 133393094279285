import { ThemeProvider } from '@mui/material';
import { theme } from './theme/theme';
import { QueryClientProvider } from '@tanstack/react-query';
import { queryClient } from './api/query-client';
import { Suspense } from 'react';
import { RouterProvider } from '@tanstack/react-router';
import { router } from './router/router.tsx';

function App() {
    return (
        <Suspense>
            <ThemeProvider theme={theme}>
                <QueryClientProvider client={queryClient}>
                    <RouterProvider router={router} />
                </QueryClientProvider>
            </ThemeProvider>
        </Suspense>
    );
}

export default App;
